@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap')

//$theme-color: $secondary-color
$white-color: #FFFFFF
$line-color: #D2D2D2
$label-color: #252525de//#252525
$border-color: #BCBCBC
$font-color: #626262
$reject-btn-bg: #FFEAEA
$border-dark: #707070

:root
    --primary-color: null
    --secondary-color: null

:root
  //--btn-primary-color: #24aedf
  //--primary-color: #007AA8
  //--secondary-color: #24aedf
  //--delete-icon-color: #F00
  //--edit-icon-color: #24aedf 
  //--common-icon-color: #24aedf
  //--application-login-interface: #24aedf


$btn-primary-color: var(--secondary-color)
$primary-color: var(--primary-color)
$secondary-color: var(--secondary-color)
$delete-icon-color: #F00
$edit-icon-color: var(--secondary-color)
$common-icon-color: var(--secondary-color)
$application-login-interface: var(--secondary-color)


%login-button-pty
    background: $btn-primary-color !important
    border-color: $btn-primary-color !important
    color: $white-color
%reject-btn-pty
    color: $secondary-color
    background: $reject-btn-bg
    border-color: $reject-btn-bg

%success-btn-pty
    color: $white-color
    background: #893933
    border-color: #893933

@font-face
    font-family: 'Montserrat', sans-serif
    src: url("/fonts/Montserrat-Regular.ttf")

body
    // font-family: 'Montserrat', sans-serif
    background-color: #f6f6f6
    font-family: "Montserrat", Helvetica, Arial, serif !important
    font-size: 1rem
    font-weight: 400
    line-height: 1.45
    color: #626262

    .learn-more-btn:hover
        background: rgb(129, 140, 248)!important
        color: rgb(0, 7, 75) !important

    .create-ticket-form .ant-form-item-label, .manage-form .ant-form-item-label, .create-bridge-form .ant-form-item-label, .create-snapshot-form .ant-form-item-label
        text-align: left

    .net-info-modal > div:nth-of-type(2n + 1)
        background: rgba(36,174,223, .2)
label
    color: $font-color
    text-transform: capitalize
.fw
    font-weight: 500
.ant-image-mask
    display: none
.ant-form-item-label > label
    font-size: 1.05rem
    // color: $font-color
    color: #181818
.ant-input
    .text-input
        background-color: $white-color
.bg
    position: fixed
    top: -50%
    left: -50%
    width: 200%
    height: 200%
.bg
    img
        position: absolute
        top: 0
        left: 0
        right: 0
        bottom: 0
        margin: auto
        min-width: 50%
        min-height: 50%
.form-center
    background-color: white
    border-radius: 8px
    position: absolute !important
    left: 50%
    top: 50%
    transform: translate(-50%, -50%)
    width: 100%
.center
    text-align: center
.text-color
    color: $secondary-color !important
    text-decoration: none
    font-size: 11px
    font-weight: 600
.hide-password-icon
    .ant-input-suffix
        display: none !important
.login-form .inputLabel
    margin: 12px 0
.forgot-pwd
    margin-bottom: 10px
    font-size: 12px
    margin-top: -10px
.login-button,.ant-btn-primary
    background: $btn-primary-color
    color: $white-color
    border-radius: 10px
    border-color: $btn-primary-color
.login-button:hover, .ant-btn-primary:hover
    @extend %login-button-pty
.login-button:focus, .ant-btn-primary:focus
    @extend %login-button-pty
.login-button:active, .ant-btn-primary:active
    @extend %login-button-pty
.signup-link
    font-size: 12px
    font-weight: 600
.login-img
    text-align: center
    // font-size: 25px
    //background-color: #e6e6fa
    border-top-left-radius: 5px
    border-top-right-radius: 5px
.logo-text
    text-align: center
    font-size: 23px
    color: $secondary-color
    font-weight: 600
    padding-left: 10px
.logo-with-text
    color: white
    font-weight: 600
    padding-left: 10px
    font-size: 18px
    // position: absolute
.logo-text-name
    color: white
    font-weight: 900
    font-size: 24px
    text-align: center
.login-icon-text
    color: $font-color
    font-weight: 800
    // padding-right:10px
#header
    text-align: center
    font-size: 1.8rem
    font-weight: 500
    color: $secondary-color
.text-input
    font-size: 1rem
    border: 1px solid $secondary-color!important
    background-color: transparent
    color: #181818
    border-radius: 5px!important
    padding: 8px 11px
.inputLabel
    font-size: 10px
    font-weight: 500
.enquiry
    position: fixed
    bottom: 4%
    right: 3%
.help
    background-color: $white-color
    color: black
    border-radius: 6px
    padding: 8px 12px
    font-size: 9px
    font-weight: 600
.caret-icon
    font-size: 16px
    position: absolute
    bottom: 42%
    left: 28%
    color: $white-color
.help-icon
    padding: 4px 6px
    background-color: $white-color
    border-radius: 4px
    display: flex
    justify-content: center
    width: 36%
    margin-top: 10px
    position: relative
    left: 20%
.text-input:hover, .text-input:focus, .text-input:active
    //border-color: $secondary-color !important
    // box-shadow: inset 0 0 0 0 $secondary-color !important
    border: 1px solid rgb(36, 174, 223) !important
    box-shadow: inset 0 0 0 0 #181818 !important

.ant-form-item-explain-error
    font-size: 12px
    padding-top: 6px
    padding-bottom: 6px
    font-weight: 600
    text-align: start

// Loader

.anticon-spin
    color: $secondary-color
.text-center
    text-align: center

.modal-text-center
    text-align: center
    font-size: 17px
    font-weight: bold
// Login

.ant-alert-message
    color: #C6B794
.login-logo
    text-align: center
.admin-login-logo
    width: 50vw
.input-pwd
    position: relative
.hide-icon
    cursor: pointer
    position: absolute
    font-size: 25px
    right: 10px
    top: 12px
    color: #181818 !important
// .admin-login
    .login-content
        border-radius: 10px!important

    .form
        // background-color: #e6e6fa
        border-radius: 500px
    #header
        padding-top: 20px
        padding-bottom: 20px
    .form-content
        position: relative
        display: flex
        flex-direction: column
        align-items: center
    .ant-form
        padding: 1rem
        width: 100%
    .alert-style
        // margin-top: 25px
        position: fixed !important
        top: 20px !important
        right: 20px !important
        width: 300px
        @media ( min-width: 700px )
            width: 500px

.copyrights-text
    font-size: 13px
    z-index: 2
    color: #181818
    font-weight: 500
    position: fixed
    right: 25px
    bottom: 25px
.company-admin
  // overflow: hidden
label
    font-size: 13px
.ant-drawer-body
    padding: 0 !important
.navbar
    background-color: #fff
    height: 70px
    display: flex
    justify-content: space-between
    align-items: center

.menu-bars
    margin-left: 2rem
    font-size: 2rem
    background: none

.nav-text
    padding: 18px 0px 18px 25px
    display: flex
    flex-direction: column
    border-bottom: 1px solid $secondary-color
    label
        color: $secondary-color
        font-size: 16px
        cursor: pointer
    & a:hover
        background-color: #ffffff

.nav-menu-items
    width: 100%
    padding-left: 0

.navbar-toggle
    align-items: center
    padding: 28px 24px 14px
    border-bottom: 1px solid $secondary-color
    display: flex
    justify-content: space-between
.menu
    font-weight: 600
.sub-menu
    margin-top: 1rem
    margin-left: 1rem
.company-logo
    margin-left: 2rem
.navbar-right
    float: right
    display: flex
    align-items: center
    margin-right: 4rem
    cursor: pointer
.username
    display: flex
    flex-direction: column
    margin-left: 0.5rem
.header
    font-size: 16px
    font-weight: 600
// .company-admin, .edit-modal
// .ant-form-item-has-error:focus,
// .ant-form-item-has-error :not(.ant-input-disabled).ant-input:focus,
// .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper:focus,
// .ant-form-item-has-error :not(.ant-input-disabled).ant-input-focused,
// .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper-focused
//     box-shadow: none !important

    // admin  layout
.trigger
    padding: 0 24px
    font-size: 18px
    line-height: 64px
    cursor: pointer
    transition: color 0.3s

    &:hover
        color: $secondary-color
// #000c17
.logo
    height: 32px
    margin: 16px
    margin-bottom: 30px

.site-layout .site-layout-background
    background: #fff
.ant-layout-sider
    max-width: 240px !important
    min-width: 240px !important
    width: 240px !important
.ant-layout-sider-collapsed
    max-width: 80px !important
    min-width: 80px !important
    width: 80px !important
.ant-layout-sider, .ant-menu, .ant-menu.ant-menu-sub.ant-menu-inline
    background: $primary-color
.ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub
    background: $primary-color
.ant-layout-sider
    svg
        color: whitesmoke !important
ant-menu-dark
        background: #fff !important
.ant-menu-title-content
    font-size: 15px
    color: whitesmoke
.ant-menu-item-selected
    background-color: $secondary-color !important
    border-radius: 5px
    span
        color: #fff !important
.ant-menu-item-icon
    color: #565656
.css-nwmyek .ant-menu-item-selected span
    color: $secondary-color !important
.css-nwmyek .ant-menu li span:hover
    color: $secondary-color !important
.ant-menu-submenu-arrow
    background: whitesmoke !important
    opacity: 1 !important
    &:before
        background: whitesmoke !important
    &:after
        background: whitesmoke !important
    &:hover
        background: whitesmoke !important
        border-radius: 5px
.ant-menu-sub .ant-menu-title-content
    font-size: 14px
.ant-menu-item-active, .ant-menu-item-active
    background-color: $secondary-color!important
    border-radius: 5px
    .ant-menu-title-content, .ant-menu-item-icon
        color: #FFFFFF !important
.ant-dropdown-trigger
    float: right
    padding-right: 30px
    padding-top: 10px
    cursor: pointer
.ant-layout-header
    line-height: 16px
// profile
.title label
    font-weight: 600
    letter-spacing: 0.05rem
    color: #2c2c2c
    font-size: 14px

// common
.ant-btn
    height: 38px
    padding: 4px 22px
.ant-input, .ant-select-selector
    border-radius: 5px !important
.ant-input-disabled
    color: #5f5f5f !important

.sweet-alert a
    background-color: $secondary-color!important
    color: #fff
    padding: 12px 35px
    border-radius: 10px

.ant-checkbox-checked .ant-checkbox-inner
    background-color: $secondary-color
    border-color: $secondary-color

// table
//     border-spacing: 0
//     border-collapse: revert
//     width: 100%
//     font-size: 13px
// thead
//     text-align: left
// .search-textbox
//     font-size: 14px
//     margin-bottom: 1.5rem
//     padding: 5px 15px
//     border: 1px solid $border-color !important
//     border-radius: 6px
//     &:hover, &:active, &:focus
//         border: 1px solid $border-color !important
//         box-shadow: none

// .data-th
//     color: $secondary-color
//     font-weight: 600
//     border-bottom: 1px solid $secondary-color
//     padding-bottom: 0.5rem
//     padding: 10px

// .data-td
//     padding: 10px
//     font-weight: 500
//     color: #252525
//     border-bottom: 1px solid $border-color
//     word-break: break-word
// .table-pagination
//     margin-top: 10px
//     width: 100%
//     font-size: 13px
.delete
    color: red
    cursor: pointer

.edit
    color: $secondary-color
    cursor: pointer
    margin-right: 8px

.about
    font-size: 14px
    line-height: 26px
    padding-top: 20px

.table-row-light
    background-color: #24abdb45

.device-token
    // td:nth-child(2)
    //     width: 200px
    // td:nth-child(2)
    //     width: 200px
    // td:nth-child(3)
    //     width: 600px

.ant-table-tbody > tr > td
    word-wrap: break-word
    word-break: break-all

.copy-token
    cursor: pointer
.current-date
    flex: 1
    text-align: end
    // float: right
    position: relative
    // left: 55%
    font-size: 13px

.leaflet-container
    height: 500px
    width: 100%
    z-index: 1
.eye-icon
    font-size: 18px
    color: $secondary-color
.badge
    padding: 0.4em 0.6rem !important
    display: inline-block !important
    font-size: 70% !important
    font-weight: 600 !important
    text-transform: uppercase !important
    border-radius: 10rem !important
.badge-light-success
    color: #28c76f!important
    background-color: #c7f4db !important
.badge-light-danger
    color: #ea5455!important
    background-color: #ea545524 !important
.badge-light-yellow 
    color: #ffbc00 !important
    background-color: #fff3cd !important

.bullet
    width: 0.714rem
    height: 0.714rem
    border-radius: 50%
.bullet-success
    background-color: #28c76f
.bullet-danger
    background-color: #ea5455
.device-list
    .ant-btn-lg
        width: 35px
        height: 35px
        padding: 4px 0
        border-radius: 8px
    .ant-btn-primary
        background-color: #28c76f
        border-color: #28c76f
    .ant-btn-danger
        background-color: #ea5455
    .warning
        background-color: #faad14
        color: #fff

.dashboard
    overflow: hidden
    background-color: #f0f2f5
    .c-panel
        justify-content: space-between
        background-color: #fff
        margin-top: 15px
        border-radius: 8px
    .d-panel
        display: flex
        justify-content: space-between
        background-color: #fff
        padding: 22px 22px 14px
        border-radius: 8px

    .d-title
        font-size: 13px
        font-weight: 600
        letter-spacing: .05rem
        color: #2c2c2ceb
        padding-top: 0.6rem
    .d-count
        font-weight: 600
        font-size: 20px
        color: #2c2c2c
    .tunnel-count
        font-size: 15px
        vertical-align: middle
        padding-left: 5px
        padding-right: 10px
    .leaflet-container
        background: transparent !important
        width: 80%
        position: absolute
        top: 32%

.leaflet-control-attribution
    display: none
.graphCnt
    position: absolute
    top: 40%
    font-size: 12px
    font-weight: 600

.graphTxt
    font-size: 12px
    font-weight: 600
    text-align: center
.twitter-picker
    width: 456px !important
    left: 7%
.selected-color
    height: 28px
    width: 30px
    position: absolute
    bottom: 11%
    left: 10%
    border-radius: 3px

.ant-switch-checked
    background-color: $secondary-color
.ant-menu-item.external-link
    padding-left: 24px
.external-link:hover
    background-color: $secondary-color!important
    .ant-menu-title-content
        :hover > &
            color: #fff!important
    .ant-menu-item-icon
        :hover > &
            color: #fff!important

.ant-notification-notice
    background-color: #E6F7FF

.notification-success
    background-color: #F6FFED

.notification-error
    background-color: #FFF2F0

.skeleton
    animation: skeleton-loading 1s linear infinite alternate
    border-radius: 5px

@keyframes skeleton-loading
    0%
        background: hsl(200, 20%, 100%)

    100%
        background: hsl(200, 20%, 90%)

.home
    margin: 5px
    text-align: center
    .features
        font-size: 15px
        h2
            //color: #007AA8
            text-align: left
            padding-left: 20px
        // margin-top: 5px
        // li
        //     text-align: left
        //     list-style-type: none
        //     line-height: 2
        //     margin:2px
        //     padding-left:3px
        //     background: #fff
        //     &:nth-child(odd)
        //         background: #e6f8ff
    .dashboard
        overflow: hidden
        // background: #fff
        .d-card
            height: 150px
            border-radius: 8px
            .ant-card-body
                font-size: 15px
                font-weight: 600
                //color: #007AA8
                letter-spacing: .02rem
                margin-top: 20px
        .d-card-agents
            border-radius: 8px
            background-color: $secondary-color
            color: #fff
            h2
                color: #fff
            li
                text-align: left
                line-height: 2.5
                color: #fff
                letter-spacing: .02rem
        .d-card-agent
            height: 150px
            border-radius: 8px
            background-color: $secondary-color
            .ant-card-body
                font-size: 16px
                font-weight: 600
                color: #fff
                letter-spacing: .02rem
                margin-top: 20px
/* CSS for AdminLayout1 */
.add-button-container
    display: flex
    justify-content: flex-end
    padding: 16px

.add-button-container button
    margin-left: 8px

.anticon anticon-minus-square, .ant-tree-switcher-line-icon,.ant-tree-switcher-leaf-line::after
    background-color: $primary-color
    border-bottom: none

.ant-tree-show-line .ant-tree-switcher,.ant-tree-list-holder-inner
    background: $primary-color

.title-container
    display: flex
    justify-content: space-between
    align-items: center
    //margin-right: 13px
    font-size: 16px

.ant-tree-title > .title-container > .title
    flex: 1
.ant-tree-title > .title-container > .ant-btn
    height: 28px
    padding: 2px 8px 2px
    border-radius: 5px
.ant-tree-treenode, .ant-tree-node-content-wrapper
    width: 100%
.title
    color: #fff

.title-container .anticon-plus-circle, .title-container .anticon-delete
    display: none
    position: absolute
    top: 0
    right: 0
    color: blue
    cursor: pointer

.title-container:hover .anticon-plus-circle
    display: inline-block
    margin-right: 24px

.title-container:hover .anticon-delete
    display: inline-block
    margin-right: 3px

.title-container:hover
    background-color: transparent !important

.title-container:hover .add-sub-button
    background-color: transparent !important
.ant-tree-node-content-wrapper, .ant-tree-node-content-wrapper-normal
    background-color: transparent !important
.ant-tree-switcher-leaf-line
    display: flex
    align-items: center
    justify-content: center
.ant-tree-show-line .ant-tree-indent-unit::before
    border-right: none
.nest-btn
    display: flex
    justify-content: space-between

.ant-tree-switcher .anticon-plus-square
    display: none

.rec-btn
    margin-right: 10px
    margin-top: 26px

.inter .ant-form-item-label > label
    white-space: normal
    padding-bottom: 8px
.vlan-r
    float: right
    margin-top: 15px
    margin-bottom: 15px

.ant-collapse
    margin-bottom: 10px
.ant-statistic-content
    color: $secondary-color
.qos-form .ant-form-item-label
    text-align: left

.ant-table-thead > tr > th
  background: $primary-color !important
  color: white
  &:hover
    background: $secondary-color !important
    color: white

.login-background
    background: $application-login-interface
.text-color-primary
    color: $primary-color !important
.text-color-secondary
    color: $secondary-color !important        
.background-color-primary
    background: $primary-color !important
.background-color-secondary
    background: $secondary-color !important   
.common-icon
    color: $common-icon-color     
.edit-icon
    color: $edit-icon-color
.delete-icon
    color: $delete-icon-color
.market-place-title::first-letter
    font-size: 30px 
    font-weight: 900
    color: $secondary-color
    border-bottom: 2px solid $secondary-color

.ant-tabs-tab:hover,
.ant-menu-item:hover,
.ant-menu-item-only-child:hover,
.ant-menu-item-active:hover,
.ant-menu-item-active,
.ant-menu-item
  color: $secondary-color !important


.ant-tabs-tab-active .ant-tabs-tab-btn
    text-shadow: 0 0 0.25px currentcolor !important
    color: $secondary-color !important

.ant-tabs-tab-btn:hover
    color: $secondary-color !important
.ant-tabs-ink-bar
    background-color: $secondary-color

.ant-btn-link
    color: $secondary-color !important  

//report card

.page-title
  font-weight: 800
  color: #222
  letter-spacing: 0.1px
  font-size: 1.1rem

.report-devices
  display: flex
  margin-left: 1em
  margin-top: 2em
  gap: 20px
  flex-wrap: wrap

  .report-card
    display: inline-block
    padding: 1em
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px
    display: flex
    align-items: center
    height: 80px
    justify-content: space-between
    width: 40%

.report-card
  &:hover
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px
    transform: translateX(2px)
    border-radius: 10px
    transition: 0.5s

  p
    font-weight: 900
    margin: 0
    letter-spacing: 0.6px
    font-size: 0.9rem
    text-align: justify

  .download-btn
    padding: 0

  .report
    display: flex
    align-items: center
    column-gap: 20px

.download-icon
  cursor: pointer
  
.ant-message-loading .anticon
    color: var(--secondary-color) !important
  
.ant-progress-inner .ant-progress-bg
  background-color: $primary-color !important

.chart-title
    color: $secondary-color

a:focus, button:focus
    outline: unset !important

//margin-shortcut
.m-10
    margin: 10px !important
.m-15
    margin: 15px !important
.m-20
    margin: 20px !important
.m-25
    margin: 25px !important
.m-30
    margin: 30px !important


.mt-10
    margin-top: 10px !important
.mt-15
    margin-top: 15px !important
.mt-20
    margin-top: 20px !important
.mt-25
    margin-top: 25px !important
.mt-30
    margin-top: 30px !important
.mt-40
    margin-top: 40px !important


.ml-10
    margin-left: 10px !important
.ml-15
    margin-left: 15px !important
.ml-20
    margin-left: 20px !important
.ml-25
    margin-left: 25px !important
.ml-30
    margin-left: 30px !important
.ml-40
    margin-left: 40px !important

.mr-10
    margin-right: 10px !important
.mr-15
    margin-right: 15px !important
.mr-20
    margin-right: 20px !important
.mr-25
    margin-right: 25px !important
.mr-30
    margin-right: 30px !important

.mb-10
    margin-bottom: 10px !important
.mb-15
    margin-bottom: 15px !important
.mb-20
    margin-bottom: 20px !important
.mb-25
    margin-bottom: 25px !important
.mb-30
    margin-bottom: 30px !important    

//flex shortcut-properties
.d-flex
    display: flex !important  
.flex-direction-column
    flex-direction: column
.justify-content-between
    justify-content: space-between !important  
    
.justify-content-evenly
    justify-content: space-evenly !important  

.justify-content-around
    justify-content: space-around !important  

.justify-content-center
    justify-content: center !important  

.justify-content-start
    justify-content: flex-start !important  

.justify-content-end
    justify-content: flex-end !important  

.align-items-center
    align-items: center !important  

.align-items-end
    align-items: flex-end !important  

.align-items-start
    align-items: flex-start !important  

.align-items-line
    align-items: baseline !important 

.flex-1
    flex: 1 !important      
.flex-wrap
    flex-wrap: wrap  

.column-gap-10
    column-gap: 10px 

.column-gap-20
    column-gap: 20px

.gap-10
    gap: 10px

.gap-20
    gap: 20px    


//font    
.font-weight-bold
    font-weight: bold

.font-weight-light
    font-weight: light

//width
.width-100
    width: 100% !important

.letter-space-1
    letter-spacing: 1px

.ant-table-placeholder
    z-index: unset !important
